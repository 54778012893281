import React, { useEffect, useState } from "react"
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import XLSX from 'xlsx'

import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import FilterSearch from 'pages/parts/FilterSearch'
import Pagination from 'pages/parts/Pagination'
import DatePicker from "react-datepicker"

import { SET_REMEMBER_LAST_DOCUMENTIN_PREVIEW } from "store/settings/actions"
import { PGET, GET } from 'helpers/api'
import { checkRole, findFromArrayById, formatDate, formatDateBackend, formatDateWithTime, formatMoney } from 'helpers/helpers'
import useDidMountEffect from "pages/parts/useDidMountEffect"
import TableControl from "pages/parts/TableControl"

// import youtube from "assets/images/youtube.png"

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const reduxSettings = useSelector(state => state.settings)
	const reduxAccount = useSelector(state => state.account)
	const reduxFilterData = useSelector(state => state.filterData)

	const date = new Date()
	const URL = `/services/web/api/documents-in-pageList`

	const canCreate = checkRole('POS_DOCUMENT_IN_ADD')

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [modalTableControl, setModalTableControl] = useState(false)

	const [filterData, setFilterData] = useState({
		posId: reduxAccount.posId,
		organizationId: "",
		startDate: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
		endDate: formatDateBackend(new Date()),
		search: '',
		page: 0,
		random: 0
	})
	const [pageCount, setPageCount] = useState(0)
	const [poses, setPoses] = useState([])
	const [organizations, setOrganizations] = useState([])
	const [data, setData] = useState([])

	async function exportToExcel(item) {
		const response = await GET('/services/web/api/documents-in/' + item.id + '?size=10000')

		var excelHeaders = [[t('POS'), t('SUPPLIER'), t('NAME_OF_PRODUCT'), t('BARCODE'), t('UNIT'), t('ADMISSION_PRICE'),
		t('SERIAL'), t('EXPIRATION_DATE'), t('QUANTITY'), t('WHOLESALE_PRICE'),
		t('UNIT_PRICE'), t('VAT'), t('AMOUNT')]]
		for (let i = 0; i < response.productList.content.length; i++) {
			var excelItems = []
			excelItems.push(item.posName)
			excelItems.push(item.organizationName)
			excelItems.push(response.productList.content[i].productName)
			excelItems.push(response.productList.content[i].barcode)
			excelItems.push(response.productList.content[i].uomName)
			excelItems.push(response.productList.content[i].price)
			excelItems.push(response.productList.content[i].serial)
			excelItems.push(formatDate(response.productList.content[i].expDate))
			excelItems.push(response.productList.content[i].quantity)
			excelItems.push(response.productList.content[i].wholesalePrice)
			excelItems.push(response.productList.content[i].salePrice)
			excelItems.push(response.productList.content[i].vat)
			excelItems.push(response.productList.content[i].totalAmount)
			excelHeaders.push(excelItems)
		}
		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('GOOD_RECEPTION') + ".xlsx");
	}

	function resetFilter() {
		setFilterData({
			...filterData,
			search: '',
			random: filterData.random + 1,
			startDate: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
			endDate: formatDateBackend(new Date()),
		})
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.organizationId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}

	async function getData() {
		checkFilter()
		const response = await PGET(URL, { ...filterData }, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	function redirectPreview(id) {
		dispatch(SET_REMEMBER_LAST_DOCUMENTIN_PREVIEW(id))
		navigate(`/documents-in/preview/${id}`)
	}

	async function paginate(data) {
		const response = await PGET(URL, { ...filterData, page: data.selected, size: reduxSettings.size }, { loader: true })
		setFilterData({ ...filterData, page: data.selected })
		setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
		setData(response.data);
	}

	async function getPoses() {
		const response = await GET("/services/web/api/pos-helper")
		if (response) {
			response.unshift({ 'id': null, 'name': t('ALL') })
			setPoses(response)
		}
	}

	async function getOrganizations() {
		const response = await GET("/services/web/api/organization-helper")
		if (response) {
			setOrganizations(response)
		}
	}

	useEffect(() => {

		if (reduxFilterData?.documentsIn && reduxSettings?.documentsInRememberFilter) {
			setFilterData({ ...filterData, ...reduxFilterData?.documentsIn, random: filterData.random + 1 })
		} else {
			getData()
		}
		getPoses()
		getOrganizations()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		if (reduxSettings?.documentsInRememberFilter) {
			dispatch({ type: 'SET_FILTER_DATA', payload: { key: 'documentsIn', value: { ...filterData } } })
		}
	}, [filterData]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		getData()
	}, [filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>


			<div className="card">
				<div className="card-body">


					<div className="d-flex justify-content-between">
						<div className="d-flex">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}
									reduxFilterSearch={true}
								/>
							</div>
							<div>
								<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('ORGANIZATION')}</label>
												<Select
													options={organizations}
													value={organizations.find(option => option.id === filterData.organizationId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'organizationId': option?.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
													isClearable
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('PERIOD')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.startDate ? new Date(filterData.startDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.endDate ? new Date(filterData.endDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button type="button" className="btn btn-outline-danger me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button type="button" className="btn btn-outline-primary" onClick={() => setFilterData({ ...filterData, page: 0, random: filterData.random + 1 })}>
														{t('APPLY')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>

						<div className="d-flex gap-3">

							{/* <div>
									<img src={youtube} alt="" width={40} height={40} />
								</div> */}

							{canCreate &&
								<Link className="btn btn-outline-primary px-4" to="/documents-in/create">
									{t('CREATE')}
								</Link>
							}
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block my-3">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.startDate || filterData.endDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.startDate &&
											<span>{formatDate(filterData.startDate)}</span>
										}
										{(filterData.startDate && filterData.endDate) &&
											<span>|</span>
										}
										{filterData.endDate &&
											<span>{formatDate(filterData.endDate)}</span>
										}
									</span>
								}
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.organizationId &&
									<span className="me-2">
										<b>{t('ORGANIZATION')}: </b> {findFromArrayById(organizations, filterData.organizationId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-danger text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th className="table-row-number">№</th>
									{(reduxSettings?.tableDocumentsIn?.pos) &&
										<th>{t('POS')}</th>
									}
									<th>{t('SUPPLIER')}</th>
									{(reduxSettings?.tableDocumentsIn?.inNumber) &&
										<th>№ {t('OVERHEAD')}</th>
									}
									<th className="text-end">{t('RECEIPT_AMOUNT')}</th>
									<th className="text-end">{t('SALE_AMOUNT')}</th>
									<th className="text-end">{t('DIFFERENCE')}</th>
									{(reduxSettings?.tableDocumentsIn?.currencyName) &&
										<th className="text-end">{t('CURRENCY')}</th>
									}
									{(reduxSettings?.tableDocumentsIn?.note) &&
										<th className="text-end">{t('NOTE')}</th>
									}
									<th className="text-center">{t('DATE_OF_RECEIPT')}</th>
									{(reduxSettings?.tableDocumentsIn?.createdBy) &&
										<th className="text-end">{t('RECEIVED_BY')}</th>
									}
									<th className="text-center table-control">
										{t('ACTION')}
										<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}</td>
										{(reduxSettings?.tableDocumentsIn?.pos) &&
											<td>
												<span className={reduxSettings?.rememberLastDocumentInPreview === item.id ? 'border-bottom-primary' : ''}
													title={reduxSettings?.rememberLastDocumentInPreview === item.id ? t('LAST_SEEN_DOCUMENT') : ''}>
													{item.posName}
												</span>
											</td>
										}
										<td>{item.organizationName}</td>
										{(reduxSettings?.tableDocumentsIn?.inNumber) &&
											<td>{item.inNumber}</td>
										}
										<td className="text-end">{formatMoney(item.totalAmount)}</td>
										<td className="text-end">{formatMoney(item.totalSaleAmount)}</td>
										<td className="text-end">{formatMoney(item.totalDifference)}</td>
										{(reduxSettings?.tableDocumentsIn?.currencyName) &&
											<td className="text-end">{item.currencyName}</td>
										}
										{(reduxSettings?.tableDocumentsIn?.note) &&
											<td>{item.note}</td>
										}
										<td className="text-center">{formatDateWithTime(item.createdDate)}</td>
										{(reduxSettings?.tableDocumentsIn?.createdBy) &&
											<td className="text-end">{item.createdBy}</td>
										}
										<td>
											<div className="table-actions">
												{item.completed ?
													<div className="table-action-button table-action-primary-button"
														onClick={() => redirectPreview(item.id)}>
														<i className="uil-eye"></i>
													</div>
													:
													<Link className="table-action-button table-action-primary-button"
														to={`/documents-in/update/${item.id}`}>
														<i className="uil-edit-alt"></i>
													</Link>
												}

												<div className="table-action-button table-action-success-button" onClick={() => exportToExcel(item)}>
													<i className="uil-file-alt"></i>
												</div>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate} reduxPage={filterData.page}></Pagination>
				</div>
			</div>

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableDocumentsIn'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default Index
