import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import Select, { components } from 'react-select'
import { Dropdown } from 'react-bootstrap'

import useDidMountEffect from 'pages/parts/useDidMountEffect'
import Pagination from 'pages/parts/Pagination'

import { GET, PGET, POST } from 'helpers/api'
import { checkRole, findFromArrayById, returnSign } from "helpers/helpers"
import { SET_TABLE_FILTER } from 'store/tableFilter/actions';
import { DebounceInput } from "react-debounce-input"
import { toast } from "react-toastify"

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const reduxSettings = useSelector(state => state.settings)
	const reduxTableFilter = useSelector(state => state.tableFilter)

	const canCreate = checkRole('MAIN_PRODUCTS_ADD')
	const canUpdate = checkRole('MAIN_PRODUCTS_EDIT')

	const seasons = [{ id: 0, name: t('EMPTY') }, { id: 1, name: t('SEASONAL') }, { id: 2, name: t('NOT_SEASONAL') }];


	const [isOpen, setIsOpen] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)

	const [pageCount, setPageCount] = useState(0)

	const [categories, setCategories] = useState([])
	const [categoriesInitial, setCategoriesInitial] = useState([])
	const [data, setData] = useState([])
	const [packages, setPackages] = useState([])

	const [selectedItem, setSelectedItem] = useState({
		'gtin': '',
		'categoryId': '',
		'complete': '',
		'page': '',
	})
	const [filterData, setFilterData] = useState({
		'search': '',
		'categoryId': '',
		'complete': '',
		'page': '',
	})

	async function searchWithFilter(settings = { tableSort: '' }) {

		var filterUrl = "/services/web/api/product-pageList"
		var urlParams = ""
		checkFilter()

		// if (filterData?.categoryId)
		// 	urlParams += returnSign(urlParams) + 'categoryId=' + filterData?.categoryId
		// if (filterData?.seasonal)
		// 	urlParams += returnSign(urlParams) + 'seasonal=' + filterData?.seasonal
		// if (filterData?.complete)
		// 	urlParams += returnSign(urlParams) + 'complete=' + filterData?.complete
		// if (filterData?.search)
		// 	urlParams += returnSign(urlParams) + 'search=' + filterData?.search
		// if (filterData?.page)
		// 	urlParams += returnSign(urlParams) + 'page=' + filterData?.page

		if (`/services/web/api/product-pageList${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		if (settings.tableSort) {
			var filterDataCopy = { ...filterData }
			if (filterDataCopy.tableSortKey === settings.tableSort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = settings.tableSort

			setFilterData(filterDataCopy)
			urlParams += returnSign(urlParams) + 'sort=' + settings.tableSort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams

		const response = await PGET(`/services/web/api/product-pageList${urlParams}`, filterData, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
			setData(response.data)
		}

		setDropdownIsOpen(false)
	}

	async function productSetGtin() {
		const response = await POST('/services/web/api/product-set-gtin', selectedItem, { loader: true, message: true })
		if (response) {
			setSelectedItem({ ...selectedItem, id: '' })
		}
	}

	async function searchOfdProduct() {
		const response = await GET(`/services/web/api/get-ofd-product?gtin=${selectedItem.gtin}`, {}, { loader: true })
		if (response && response.gtin) {
			setSelectedItem({ ...selectedItem, 'name': response.name, 'gtin': response.gtin, })
			setPackages(response.packages)
		} else {
			return toast.error('ИКПУ ' + t('NOT_FOUND'))
		}
	}

	function checkFilter() {
		if (
			!filterData.categoryId &&
			!filterData.complete &&
			!filterData.page &&
			!filterData.search
		) {
			setFilterDataExist(false)
		}
	}

	function resetFilter() {
		setFilterData({
			categoryId: null,
			complete: null,
			search: '',
		})
		setFilterDataExist(false)
		searchWithFilter()
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(data) {
		setFilterData({ ...filterData, 'page': data.selected })
	}

	async function getCategories() {
		const response = await GET('/services/web/api/product-category-helper')
		var arr = []
		for (let i = 0; i < response.length; i++) {
			response[i]['label'] = ' ' + response[i]['name']
			response[i].child = false;
			if (response[i].parentId !== 0) {
				const index = response.findIndex(x => x.id === response[i].parentId)
				response[i].child = true;
				if (response[index].options && response[index].options.length > 0) {
					response[index].options.push(response[i])
				} else {
					response[index].options = [response[i]]
				}
			} else {
				arr.push(response[i])
			}
		}
		setCategories(arr)
		setCategoriesInitial(response)
	}

	async function selectCategory(option) {
		setIsOpen(!isOpen)
		setFilterData({ ...filterData, 'categoryId': option?.id ? option.id : '' })
	}

	const Option = props => {
		return (
			<div style={{
				padding: props.data.child ? '0 0 0 20px' : '0', color: '#999',
				fontWeight: props.data.parentId === 0 ? 'bold' : 'normal', fontSize: '.8rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	const GroupHead = props => {
		return (
			<div style={{
				padding: '0',
				color: filterData.categoryId === props.data.id ? '#fff' : '#999',
				fontWeight: 'bold',
				backgroundColor: filterData.categoryId === props.data.id ? '#2684FF' : '#fff',
				fontSize: '.8rem'
			}}
				onClick={() => selectCategory(props.data)}>
				<components.Option {...props} />
			</div>
		);
	};

	useEffect(() => {
		getCategories()

		if (reduxTableFilter.page === 'products') {
			setFilterData(reduxTableFilter.filters)
		} else {
			searchWithFilter()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		dispatch(SET_TABLE_FILTER({
			'page': 'products',
			'filters': {
				'search': filterData.search ? filterData.search : '',
				'categoryId': filterData.categoryId ? filterData.categoryId : '',
				'complete': filterData.complete ? filterData.complete : '',
				'page': filterData.page ? filterData.page : 0,
			}
		}))
	}, [filterData]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		searchWithFilter()
	}, [filterData.page, filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-between mb-3">
						<div className="d-flex">
							<div className="me-2">
								<div className="position-relative me-2">
									<i className="uil-search input-search-icon"></i>
									<DebounceInput
										type="text"
										className="form-control"
										placeholder={t('SEARCH') + '...'}
										minLength={3}
										debounceTimeout={1000}
										value={filterData.search}
										onChange={(e) => {
											if (e.target.value.length > 0) {
												setFilterData({ ...filterData, search: e.target.value })
											} else {
												setFilterData({ ...filterData, search: '' })
											}
										}}
										onKeyUp={(e) => {
											if (e.keyCode === 13) {
												if (e.target.value.length > 0) {
													setFilterData({ ...filterData, search: e.target.value })
												} else {
													setFilterData({ ...filterData, search: '' })
												}

											}
										}}
									/>
								</div>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('CATEGORY')}</label>
												<Select
													isClearable
													components={{ GroupHeading: GroupHead, Option }}
													classNamePrefix="react-select"
													options={categories}
													value={categoriesInitial.find(option => option.id === filterData.categoryId || '')}
													onChange={(option) => selectCategory(option)}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.label}
													getOptionValue={(option) => option.id}
													onMenuOpen={() => setIsOpen(true)}
													onBlur={() => setIsOpen(false)}
													onMenuClose={() => setIsOpen(false)}
													menuIsOpen={isOpen}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('SEASONALITY')}</label>
												<Select
													isClearable
													classNamePrefix="react-select"
													options={seasons}
													value={seasons.find(option => option.id === filterData.seasonal || '')}
													onChange={(option) => setFilterData({ ...filterData, 'seasonal': option?.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('COMPLETED')}%</label>
												<input type="number" className="form-control"
													value={filterData.complete}
													onChange={(e) => setFilterData({ ...filterData, 'complete': e.target.value })} />
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label>ИКПУ</label>
													<div className="form-check form-switch form-switch-lg">
														<input type="checkbox" className="form-check-input"
															checked={filterData.gtin}
															onChange={(e) => setFilterData({ ...filterData, 'gtin': e.target.checked })} />
													</div>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button type="button" className="btn btn-outline-danger me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button type="button" className="btn btn-outline-primary" onClick={() => searchWithFilter()}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>

						{canCreate &&
							<Link className="btn btn-outline-primary" to="/products/create">
								{t('CREATE')}
							</Link>
						}
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.categoryId &&
									<span className="me-2">
										<b>{t('CATEGORY')}: </b> {findFromArrayById(categoriesInitial, filterData.categoryId)}
									</span>
								}
								{filterData.seasonal &&
									<span className="me-2">
										<b>{t('SEASONALITY')}: </b> {findFromArrayById(seasons, filterData.seasonal)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-danger text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th className="table-row-number">№</th>
									<th className="table-row-number">ID</th>
									<th className="th-sortable cursor" onClick={() => searchWithFilter({ tableSort: 'name' })}>
										{t('NAME_OF_PRODUCT')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'name') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'name') && 'sort-active')}></i>
										</div>
									</th>
									<th className="text-center">{t('BARCODE')}</th>
									<th className="text-center">{t('CATEGORY')}</th>

									<th className="text-center" style={{ width: '200px' }}>ИКПУ</th>
									<th className="text-center">OFD {t('UNIT_OF_MEASUREMENT')}</th>

									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>{item.rowNum}</td>
										<td>{item.id}</td>
										{
											selectedItem.id !== item.id ?
												<td>{item.name}</td>
												:
												<td>{selectedItem.name}</td>
										}
										<td className="text-center">{item.barcode}</td>
										<td className="text-center">{item.categoryName}</td>
										{selectedItem.id === item.id ?
											<>
												<td>
													<div className="d-flex justify-content-center">
														<div className="position-relative">
															<input type="number" className="form-control auto-width-input-2" value={selectedItem.gtin}
																onChange={(e) => setSelectedItem({ ...selectedItem, 'gtin': e.target.value })} />
															<i className="uil uil-shield-check input-right-icon" onClick={() => searchOfdProduct('gtin')}></i>
														</div>
													</div>
												</td>
												<td className="text-center">
													<Select
														tabIndex={3}
														options={packages}
														value={packages.find(option => option.id === selectedItem.packageCode)}
														onChange={(option) => setSelectedItem({ ...selectedItem, 'packageCode': option.id, 'packageName': option.name })}
														placeholder=""
														noOptionsMessage={() => t('LIST_IS_EMPTY')}
														getOptionLabel={(option) => option.name}
														getOptionValue={(option) => option.id}
													/>
												</td>
											</>
											:
											<>
												<td className="text-center">{item.gtin}</td>
												<td className="text-center">{item.packageCode ? item.packageCode : ''}</td>
											</>
										}

										<td>
											<div className="d-flex justify-content-center gap-3">
												{selectedItem.id !== item.id ?
													<>
														<div className="table-action-button table-action-primary-button" onClick={() => setSelectedItem({ ...selectedItem, ...item })}>
															<i className="uil-shield-check"></i>
														</div>
														{canUpdate &&
															<Link to={'/products/update/' + item.id}>
																<div className="table-action-button table-action-primary-button">
																	<i className="uil-edit-alt"></i>
																</div>
															</Link>
														}
													</>
													:
													<>
														<button className="table-action-button table-action-primary-button" onClick={() => productSetGtin()}>
															<i className="uil-check"></i>
														</button>
														<div className="table-action-button table-action-danger-button" onClick={() => setSelectedItem({ ...selectedItem, id: '' })}>
															<i className="uil-times"></i>
														</div>
													</>
												}
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate} filters={reduxTableFilter?.filters}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
