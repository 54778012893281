import React from 'react';

function Tree({ selectItem, children, level = 0 }) {
	return (
		<>
			{children.length > 0 &&
				children.map((item, index) => (
					<div key={index}>
						<div className={`child-${item.id} d-flex justify-content-between cursor`}
							style={{ 'marginLeft': String((level + 1) * 10) + "px", 'fontSize': '16px' }}
							onClick={() => selectItem(item)}>
							<div className="d-flex">
								{item.children.length > 0 ?
									<i className="uil uil-angle-down"></i>
									:
									<i className="uil uil-angle-right"></i>
								}
								<div>{item.id}. {item.name}</div>
							</div>
							<i className="uil-edit-alt"></i>
						</div>

						{item.children &&
							<Tree
								selectItem={selectItem}
								children={item.children}
								level={level + 1}>
							</Tree>
						}
					</div>
				))
			}
		</>
	);
}

export default Tree;
