import { getTime, format } from 'date-fns'
import { store } from '../store/index'

export function deleteSymbols(str) {
	if (str) {
		return str.replace(/[^A-Z0-9]/gi, '');
	}
	return ""
}

export function regexNumeric(str) {
	if (str) {
		return str.replace(/[^0-9]/gi, '');
	}
	return ""
}

export function returnSign(str) {
	return str.includes("?") ? '&' : '?'
}

export function regexPhoneNumber(str) {
	if (str) {
		return str.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, "$1$2 $3-$4-$5");
	}
	return ""
}

export function clearTemporaryStorage() {
	localStorage.removeItem('tokenTime')
	localStorage.removeItem('authUser')
	localStorage.removeItem('username')
	localStorage.removeItem('authorities')
	localStorage.removeItem('access_token')
	localStorage.removeItem('role')
	sessionStorage.clear();
}

export function findFromArrayById(array, id) {
	if (array.length > 0 && id) {
		return array.filter(x => x.id === id)[0]?.name;
	}
}

export function findIdFromArray(array, id) {
	if (array.length > 0 && id) {
		if (array.filter(x => x.id === id).length > 0) {
			return array.filter(x => x.id === id)[0].id;
		} else {
			return false
		}
	}
}

export function formatMoney(amount, decimalCount = null, decimal = ".", thousands = " ") {
	if (!decimalCount && decimalCount !== 0) {
		const state = JSON.parse(localStorage.getItem('state'))
		if (state) {
			//decimalCount = state.settings.decimalPoint
			decimalCount = 2
		} else {
			decimalCount = 0
		}
	}

	try {
		decimalCount = Math.abs(decimalCount);
		decimalCount = isNaN(decimalCount) ? 0 : decimalCount; // 0 was 2

		const negativeSign = amount < 0 ? "-" : "";

		let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
		let j = (i.length > 3) ? i.length % 3 : 0;

		return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
	} catch (e) {
		console.log(e)
	}
}

export function returnMinDate(date, days) {
	var result = new Date(date);
	result.setDate(result.getDate() - days);
	return result;
}

export function formatDateWithTime(date) {
	try {
		if (date) {
			var formattedDate = format(new Date(date), 'dd.MM.yyyy HH:mm');
			return formattedDate
		}
	} catch (error) {
		return date
	}
}

export function formatDate(date, formatPattern = 'dd.MM.yyyy') {
	try {
		if (date) {
			var formattedDate = format(new Date(date), formatPattern);
			return formattedDate
		}
	} catch (error) {
		return date;
	}
}

export function formatDateBackend(date) {
	try {
		if (date) {
			var formattedDate = format(new Date(date), 'yyyy-MM-dd');
			return formattedDate
		}
	} catch (error) {
		return date
	}
}

export function getUnixTime() {
	return getTime(new Date())
}

export function todayDate() {
	return format(new Date(), 'dd.MM.yyyy HH:mm:ss')
}

export function todayDDMMYYYY() {
	return format(new Date(), 'dd.MM.yyyy')
}

export function getHHmm() {
	return format(new Date(), 'HH:mm')
}

export function getUnixTimeByDate(date) {
	return getTime(date)
}

export function formatUnixTime(unixTime) {
	if (unixTime)
		return format(new Date(unixTime), 'dd.MM.yyyy HH:mm:ss')
}

export function checkRole(role) {
	const roles = store.getState()?.account.authorities;
	var item = roles?.find(item => item === role)
	if (item?.length > 0) return true
	return false;
}