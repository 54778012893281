import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import Tree from './Tree'

import { GET, POST, PUT } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState([])
	const [dataDuplicate, setDataDuplicate] = useState([])
	const [selectedItem, setSelectedItem] = useState([])
	const [showModal, setShowModal] = useState(false)

	async function createUpdate(e) {
		e.preventDefault()

		var response;
		if (selectedItem.id) {
			response = await PUT('/services/web/api/product-category', selectedItem, { loader: true })
		} else {
			response = await POST('/services/web/api/product-category', selectedItem, { loader: true })
		}

		if ((response)) {
			getData()
			toggleModal(false)
		}
	}

	function toggleModal(bool = false, item = {}) {
		setSelectedItem(item)
		setShowModal(bool)
	}

	function makeTree(list) {
		var map = {}
		var node = []
		var roots = []
		var i;

		for (i = 0; i < list?.length; i += 1) {
			map[list[i]['id']] = i; // initialize the map
			list[i]['children'] = []; // initialize the children
		}

		for (i = 0; i < list?.length; i += 1) {
			node = list[i];
			if (node.parentId) {
				// if you have dangling branches check that map[node.parentId] exists
				list[map[node.parentId]]['children'].push(node);
			} else {
				roots.push(node);
			}
		}
		return roots;
	}

	function selectItem(item) {
		var elements = document.getElementsByClassName(`child-${item.id}`);
		var selectedElements = document.getElementsByClassName('step-selected');

		for (let i = 0; i < selectedElements.length; i++) {
			selectedElements[i]['classList'].remove('step-selected');
		}

		elements[0]['classList'].add('step-selected')
		toggleModal(true, item)
	}

	async function getData() {
		var response = await GET('/services/web/api/product-category-list', {}, { loader: true })

		if ((response)) {
			//setData([...response.data])

			for (let i = 0; i < response?.data?.length; i++) {
				response[i]['selected'] = false;
				response[i]['show'] = true;
			}
			var sortedTree = makeTree(response);
			setData(sortedTree);
		}

		response.unshift({ 'id': null, 'name': t('MAIN') })
		if ((response)) {
			setDataDuplicate([...response])
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card"
				style={{
					'minHeight': 'calc(100vh - 120px)',
					'overflow': 'auto'
				}}>
				<div className="card-body">
					<div className="d-flex justify-content-end">
						<button className="btn btn-outline-primary"
							onClick={() => toggleModal(true)}>
							{t('CREATE')}
						</button>
					</div>

					<div className="d-flex flex-column w-50">
						<Tree
							children={data}
							selectItem={selectItem}>
						</Tree>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<>{t('CREATE')}</>
							:
							<>{t('EDIT')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME2')}</label>
							<input type="text" className="form-control" name="name"
								value={selectedItem.name ?? ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('CATEGORY')}</label>
							<Select
								options={dataDuplicate}
								value={dataDuplicate?.find(option => option.id === selectedItem.parentId) || ''}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'parentId': option.id })}
								placeholder=""
								noOptionsMessage={() => ""}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal()}>
								{t('CANCEL')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('SAVE')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index