import {
	FcLandscape,
	FcConferenceCall,
	FcPaid,
	FcDebt,
	FcBusinessman,
	FcCurrencyExchange,
	FcExternal,
	FcTreeStructure,
} from 'react-icons/fc'

const directory = [
	{
		icon: <FcLandscape />,
		title: 'GENERAL',
		show: false,
		children: [
			{
				icon: <FcLandscape />,
				title: 'GENERAL',
				show: false,
				children: [
					{
						title: true,
						i18n: "GENERAL"
					},
					{
						url: "/workers",
						i18n: "MY_USERS",
						icon: <FcConferenceCall />,
						role: "MAIN_USERS"
					},
					{
						url: "/categories",
						i18n: "CATEGORIES",
						icon: <FcTreeStructure />,
						role: "MAIN_PRODUCTS"
					},
					{
						url: "/products",
						i18n: "PRODUCTS",
						icon: <FcPaid />,
						role: "MAIN_PRODUCTS"
					},
					{
						url: "/suppliers",
						i18n: "SUPPLIERS",
						icon: <FcDebt />,
						role: "MAIN_ORGANIZATION"
					},
					{
						url: "/clients",
						i18n: "CLIENTS",
						icon: <FcBusinessman />,
						role: "MAIN_CLIENTS"
					},
					{
						url: "/currency",
						i18n: "CURRENCY",
						icon: <FcCurrencyExchange />,
						role: "MAIN_CURRENCY"
					},
					{
						url: "/expenses",
						i18n: "EXPENSES",
						icon: <FcExternal />,
						role: "MAIN_EXPENSE"
					},
				]
			},
		]
	}
]

export default directory